html {
  /*Variables*/
  --mainPink: #D5A99E;
  --mainGreen: #CCC7AB;
  --mainGray: #4C4E4C;

  --headerSize: 80px;
}

body {
  background-color: white;
  color: var(--mainGray);
  margin: 0px;
}

main {
  height: 100vh !important;
  min-height: 400px;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: white;
}

h1 {
  color: var(--mainGray);
  font-family: 'CMU Serif Bold', sans-serif;
  font-weight: bold;
  font-size: 2.7em;
  margin: 16px;
}

h2 {
  color: var(--mainGray);
  font-family: 'CMU Serif Bold', sans-serif;
  font-weight: bold;
  font-size: 1.7em;
  margin: 16px;
}

h3 {
  color: var(--mainGray);
  font-family: 'Cerebri Sans', sans-serif;
  font-weight: normal;
  font-size: 1.2em;
  margin: 16px;
}

p {
  color: var(--mainGray);
  font-family: 'Cerebri Sans', sans-serif;
  font-weight: normal;
  font-size: 0.9em;
  margin: 0px;
}

/*Generics*/
.flex-display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-direction { 
  flex-direction: column;
}

.flex-row-direction {
  flex-direction: row;
}

.generic-text {
  color: var(--mainGray);
  font-family: 'Cerebri Sans', sans-serif;
  font-weight: normal;
  font-size: 1em;
}

.header-container {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: auto;
}

.banner-area {
  width: 100%;
  height: 100%;
}

.banner-area-white {
  background-color: white;
}

.banner-main-container {
  width: 95%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-section {
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.banner-left-container {
  height: auto;
  margin: 16px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.banner-right-container {
  height: auto;
  margin: 16px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-center-container {
  width: 100%;
  height: auto;
  margin: 16px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.btn-main {
  min-width: 140px;
  height:60px;
  text-align: center;
  padding: 16px;
  margin: 40px 16px 8px 16px;
  font-family: 'Cerebri Sans', sans-serif;
  font-size: 1em;
  color: white;
  border-radius: 50px;
}

.btn-navena-primary {
  background-color: var(--mainPink);
}

.btn-navena-secondary {
  background-color: var(--mainGreen);
}

.btn-form {
  border: none;
  margin: 0px;
  outline: none;
}

.btn-form:focus {
  outline: none;
  border: none;
}

.navena-form {
  width: 95%;
}

.alert-invisible {
  display: none;
  position: static;
}

.alert-visible  {
  display: inline;
  position: static;
}

/*End Generics*/

.header {
  background-color: var(--mainGreen);
  width: 100%;
  height: var(--headerSize);
  position: fixed;
}

.logo {
  width: var(--headerSize);
  height: var(--headerSize);
  background-color: var(--mainGreen);
  background-image: url('../images/navena.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.navbar-main {
  background-color: var(--mainGreen);
  padding: 0px;
}

.navbar-menu {
  margin-top: 0px;
}

.navbar-toggler {
  margin-right: 16px;
}

.navbar-items {
  background-color: var(--mainGreen);  
  width: 100%;
  justify-content: flex-end;
}

.nav-item {
  margin-left: 16px; 
}

.nav-link-text {
  color: white !important;
  font-family: 'CMU Serif Roman', sans-serif;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nav-link-text:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  height: 2px;
  width: 0px;
  background-color: white;
  transition: width 0.3s ease 0s;
}

.nav-link-text:hover:after { 
  width: 0px;
}

.nav-link.active {
  color: var(--mainGray) !important;
  font-family: 'CMU Serif Roman', sans-serif;
  font-size: 1.3em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nav-link.active:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  height: 2px;
  width: 0px;
  background-color: var(--mainGray);
}

.main-banner {
  padding-top: var(--headerSize);
  width: 100%;
  height: 100%;
  background-image: url('../images/mainBackground.jpg');
  background-size: cover;
  background-position: center;
}

.main-image {
  width: 200px;
  height: 200px;
  background-image: url('../images/mainImg.jpeg');
  background-size: cover;
  border-radius: 50%;
}

.check-list {
  margin: auto;
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 0px;
  text-align: left;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: fit-content;
}

.icon-list {
  color: var(--mainGreen);
  margin: 8px;
}

.services-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-item {
  width: 90%;
}

.about-image {
  width: 150px;
  height: 150px;
  margin: 24px;
  background-image: url('../images/aboutProfile.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 50%;
}

.about-text {
  margin: 24px;
  width: 70%;
  text-align: center;
}

.footer {
  background-color: var(--mainGreen);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-icon {
  color: var(--mainPink);
  margin: 8px 0px 0px 8px;
}

.footer-icon-media {
  color: var(--mainPink);
  margin: 8px;
  color: var(--mainGray);
}

.footer-text {
  color: whitesmoke;
  margin: 8px 8px 0px 8px;
}

.footer-content {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-section {
  margin: 24px 40px 24px 40px;
}

.contact-form-input {
  text-align: left;
}

.footer-copyright {
  color: whitesmoke;
  width: 90%;
  height: auto;
  text-align: center;
  margin: 32px 24px 24px 24px;
}

.footer-img {
  width: 300px;
  height: 100px;
  background-image: url('../images/navenaIntro.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 8px;
  
}

.card {
  margin: 32px 32px 16px 32px;
}

.card-title div {
  margin: 8px;
}

/***Media Queries***/
/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
  }
  
  h3 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.9em;
  }

  .generic-text {
    font-size: 1em;
  }

  .service-item {
    width: 60%;
  }
}
 
/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .header-container {
    width: 90%;
  }

  .navbar-menu {
    margin-top: 8px;
    margin-right: 16px;
  }

  .nav-link-text {
    font-size: 1.2em;
  }

  .nav-link-text:hover:after { 
    width: 100%;
  }

  .nav-link.active {
    font-size: 1.3em;
  }

  .nav-link.active:after {
    width: 100%;
  }

  .banner-main-container {
    width: 90%;
    flex-direction: row;
  }

  .banner-left-container {
    height: 95%;
  }

  .banner-right-container {
    height: 95%;
  }

  .banner-center-container {
    width: 80%;
  }

  .main-image {
    width: 250px;
    height: 250px;
  }

  .btn-main {
    min-width: 150px;
  }

  .btn-main:hover {
    transform: scale(1.1);
    z-index: 0;
  }

  .footer-content {
    flex-direction: row;
  }

  .about-text {
    width: 50%;
  }

  .services-container {
    flex-direction: row;
    align-items: flex-start;
  }
}

/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) { 
  .header-container {
    width: 60%;
  }

  .banner-main-container {
    width: 60%;
  }
}

@media (max-height: 768px) {
  .btn-main {
    margin: 8px 16px 8px 16px;
  }

  .banner-right-container{
    display: none;
  }
}

@media (max-width: 360px) {
  h1 {
    font-size: 2em;
  }

  .main-image {
    width: 150px;
    height: 150px;
  }
}